import { TFunction } from 'i18next';
import { isFuture, parseISO } from 'date-fns';

import { Subscription, SubscriptionComponentProductStatus, SubscriptionProduct } from '../../api/crm/types';
import { InventoryTypeVariant, SUBSCRIPTION_TYPE } from '../../api/order/types';
import { ProductStatus, UpdateProductStatusParams } from '../../api/types';
import { ProductPricing } from '../../api/product/types';
import { GridActions } from '../../ui';
import { DeliveryType } from './types';

export function isValidProduct(addOn: SubscriptionProduct) {
  const subscriptionTypeValid =
    addOn?.subscriptionType === SUBSCRIPTION_TYPE.POSTPAID || addOn?.subscriptionType === SUBSCRIPTION_TYPE.PREPAID;
  const activeOrPendingForFuture =
    addOn?.status === ProductStatus.Active ||
    (addOn?.status === ProductStatus.Pending && !!addOn?.validFrom && isFuture(parseISO(addOn?.validFrom)));

  return subscriptionTypeValid && activeOrPendingForFuture;
}

export function productHasRecurringFee(product: { productPricing: ProductPricing }) {
  if (typeof product?.productPricing?.recurringFee?.price !== 'number') return false;

  return product?.productPricing?.recurringFee?.price > 0;
}

export function productHasOneTimeFee(product: { productPricing: ProductPricing }) {
  if (typeof product?.productPricing?.oneTimeFee?.price !== 'number') return false;

  return product?.productPricing?.oneTimeFee?.price > 0;
}

export function changeProductStatusAgGridActions<T extends Partial<UpdateProductStatusParams>>({
  t,
  onUpdateStatus,
  hideChangeStatusButton,
}: {
  t: TFunction;
  onUpdateStatus: (data: Partial<UpdateProductStatusParams>) => void;
  hideChangeStatusButton?: boolean;
}): GridActions<T> {
  return [
    {
      name: row => {
        switch (row?.productStatus) {
          case ProductStatus.Draft:
            return t('common:release');
          case ProductStatus.Released:
            return t('common:retire');
          default:
            return '';
        }
      },
      onClick: row => {
        const newStatus: ProductStatus =
          row?.productStatus === ProductStatus.Draft ? ProductStatus.Released : ProductStatus.Retired;
        onUpdateStatus({ id: row?.id, productStatus: newStatus });
      },
      hidden: row => hideChangeStatusButton || row?.productStatus === ProductStatus.Retired,
    },
  ];
}

export const getStatusReasonLine = (
  t: TFunction,
  { status, statusReason }: Pick<Subscription, 'status' | 'statusReason'>,
) => {
  if (!status && !statusReason) return '-';
  const translatedStatus = t(`entries:classifiers.product-status-in-subscription.${status}`);
  const translatedReason =
    status === SubscriptionComponentProductStatus.SUSPENDED && statusReason
      ? `(${t(`entries:classifiers.suspend-reason.${statusReason}`)})`
      : '';
  return `${translatedStatus} ${translatedReason}`.trimEnd();
};

export const subscriptionTypeByFirstProduct = (products?: SubscriptionProduct[]): SUBSCRIPTION_TYPE | undefined => {
  // OCB-2064 Agreed to display subscription type of first subscription product
  const firstAvailableProduct = products?.length ? products[0] : undefined;
  return firstAvailableProduct?.subscriptionType;
};

export const isElectronicDeliverySelected = (
  selectedVariantId: string | undefined,
  inventoryTypeVariants?: InventoryTypeVariant[],
) => {
  const selectedInventoryTypeVariant = inventoryTypeVariants?.find(({ id }) => id === selectedVariantId);
  return selectedInventoryTypeVariant?.deliveryType === DeliveryType.EDelivery;
};

export const shouldShowMsisdn = (inventoryTypeName: string) => {
  return inventoryTypeName === 'MSISDN';
};

export const shouldShowSerial = (inputType: string) => {
  return inputType === 'USER';
};
