import { ProductStatus, Tenant } from '../types';
import { PaymentMethodType } from '../../common/order-flow/OrderConfirmationStep/types';
import { InventoryTypeVariant, OrderCustomer, SUBSCRIPTION_TYPE } from '../order/types';
import { ConsentType } from '../../business';
import { ProductPricing } from '../product/types';

export interface ConsentItem {
  consentType?: ConsentType;
  enabled?: boolean;
}

export interface Invoice {
  billDate: string;
  invoiceId: string;
  billCycleId: string;
  billCycleIdentifier: string;
  createdAt: string;
  totalSum: number;
  currency: string;
  status: string;
}

export interface Customer extends OrderCustomer {
  createdAt?: string;
  createdBy?: CreatedBy;
  tenantId?: Tenant['id'];
  consents?: ConsentItem[];
  documents?: Document[];
}

export interface AccountsListItem {
  id: string;
  customer: string;
  tenantId: string;
  contactPhoneNr: string;
  contactEmail: string;
  language: string;
  invoiceDeliveryType: string;
  iban: string;
}

export interface ConsentsApiForm {
  items: ConsentItem[];
}
export interface SubscriptionListItemProduct {
  name: string;
  commercialName: string;
  status: string;
  subscriptionTerm: number;
  subscriptionType: string;
  validFrom: Date;
  contractStartDate: Date;
  contractEndDate: Date;
}

export interface SubscriptionsListItem {
  id: string;
  identifier: string;
  productCategory: SubscriptionProductCategory;
  products: SubscriptionListItemProduct[];
  status?: SubscriptionComponentProductStatus;
  validFrom?: Date;
  validTill?: Date;
}

export interface Subscription {
  id: string;
  identifier: string;
  customer: string;
  account: string;
  tenantId: string;
  products: SubscriptionProduct[];
  productComponents: SubscriptionProductComponent[];
  productCategory: { id: string; name: string };
  inventory?: SubscriptionInventoryField[];
  status?: SubscriptionComponentProductStatus;
  statusReason?: string;
  validFrom: Date;
  validTill: Date;
  statusHistory: StatusHistory[];
  children: SubscriptionChild[];
  services: SubscriptionServices;
}

export interface SubscriptionServices {
  roamingEnabled?: boolean;
}

export interface SubscriptionChild {
  id: string;
  customer: SubscriptionCustomer;
  identifier: string;
  status: string;
  subscriptionType: string;
  validFrom: string;
  validTill: string;
}

interface SubscriptionCustomer {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
}

interface StatusHistory {
  time: string;
  statusFrom: string;
  statusTo: string;
  eventName: string;
}

export interface SubscriptionProduct {
  orderId: string;
  offerId: string;
  productId: string;
  orderProductId: string;
  name: string;
  commercialName: string;
  subscriptionTerm: number;
  description: string;
  productCategory: SubscriptionProductCategory;
  productComponents: SubscriptionProductComponent[];
  subscriptionType?: SUBSCRIPTION_TYPE;
  status: ProductStatus;
  productFieldValues?: ProductFieldValues[];
  productPricing: ProductPricing & {
    pricingId: string;
  };
  validFrom?: string;
  validTill?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  contractRenewalFrom?: string;
  children: SubscriptionProduct[];
}

export interface ProductFieldValues {
  code: string;
  name: string;
  value: string;
}

export enum Action {
  ADD_ADDON = 'ADD_ADDON',
  CHANGE_PRODUCT = 'CHANGE_PRODUCT',
  TOP_UP = 'TOP_UP',
  CHANGE_SUBSCRIPTION_STATUS = 'CHANGE_SUBSCRIPTION_STATUS',
  SUBSCRIPTION_SETTINGS = 'SUBSCRIPTION_SETTINGS',
}

export interface SubscriptionProductCategory {
  id: string;
  tenantId: string;
  actions: Action[];
  name: string;
}

export interface SubscriptionInventoryField {
  orderProductId: string;
  inventoryId: string;
  inventoryType: SubscriptionInventoryType;
  inventoryTypeVariant?: InventoryTypeVariant;
  dataType: string;
  serial: string;
  inventoryStatus?: string;
  validFrom: Date;
  validTill: Date;
  portIn?: PortIn;
}

interface PortIn {
  donor: string;
  service: string;
}

export interface SubscriptionInventoryType {
  id: string;
  name: string;
}

export enum ServiceCategory {
  SMS = 'SMS',
  DATA = 'Data',
  VOICE = 'Voice',
}

export interface Topup {
  transactionId: string;
  topUpDate: string;
  amount: Amount;
}

export interface Amount {
  amount?: number;
  currency?: string;
}

export interface PaymentReference {
  id: string;
  type: PaymentReferenceType;
}

export interface ContactLogType {
  id: string;
  name: string;
}
export interface ContactLogForm {
  typeId: string;
  description: string;
  startRow?: number;
  endRow?: number;
  sort?: string;
  filter?: string[];
}

export interface ContactLog {
  id: string;
  type: string;
  customerId?: string;
  description: string;
  createdBy: string;
  updatedBy?: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CreatedBy {
  tenantId: Customer['tenantId'];
  username: string;
}

export interface Type {
  id: string;
  name: string;
}

export interface ContactLogResponse {
  content: ContactLog[];
  startRow: number;
  endRow: number;
  lastRow: number;
}

export interface LogsRequestParams {
  startRow?: number;
  endRow?: number;
  sort?: string;
  filter?: string[];
}

export interface ICustomerDocuments {
  documents: Document[];
}

export interface Document {
  createdAt?: string;
  createdBy?: CreatedBy;
  documentMetadataId: string;
  documentName: string;
}

export interface SubscriptionProductComponent {
  productComponentId: string;
  name: string;
  status: SubscriptionComponentProductStatus;
  validFrom: Date;
  validTill: Date;
  statusHistory: StatusHistoryItem[];
}

export interface StatusHistoryItem {
  time: Date;
  statusFrom: SubscriptionComponentProductStatus;
  statusTo: SubscriptionComponentProductStatus;
  eventName: string;
}

export interface Payment {
  id: string;
  tenantId: string;
  customerId: string;
  accountId: string;
  type: PaymentMethodType;
  amount: Amount;
  status: PaymentStatus;
  paymentReference?: PaymentReference;
  createdAt: string;
  updatedAt: string;
}

export enum PaymentStatus {
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  INITIALIZED = 'INITIALIZED',
  REFUNDED = 'REFUNDED',
  REFUNDED_INITIALIZED = 'REFUNDED_INITIALIZED',
}

export enum PaymentReferenceType {
  ORDER = 'ORDER',
  INVOICE = 'INVOICE',
  PREPAID_RENEWAL = 'PREPAID_RENEWAL',
}

export enum SubscriptionComponentProductStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  SUSPENDED = 'SUSPENDED',
  TERMINATED = 'TERMINATED',
  PORTED_OUT = 'PORTED_OUT',
  CANCELLED = 'CANCELLED',
  PRE_TERMINATED = 'PRE_TERMINATED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}
