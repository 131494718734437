import { ClassifierEntry } from '../types';

export enum PAYMENT_CLASSIFIER {
  PAYMENT_TYPE = 'paymentType',
}

export enum PaymentType {
  Online = 'ONLINE',
  PrepaidBalance = 'PREPAID_BALANCE',
  Wallet = 'WALLET',
  AccountBalance = 'ACCOUNT_BALANCE',
  Cash = 'CASH',
  PayPal = 'PAYPAL',
  TLync = 'TLYNC',
  Moamalat = 'MOAMOLAT',
}

export enum PaymentProvider {
  Paystack = 'PAYSTACK',
  Ryft = 'RYFT',
  TLync = 'TLYNC',
  Stripe = 'STRIPE',
}

export type PaymentClassifierEntry = ClassifierEntry<PAYMENT_CLASSIFIER>;
